<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-07 03:22:56
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-11 10:23:50
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\Login.vue
-->
<template>
  <div class="view-box">
    <div class="bg-1">
      <div class="title">飞鱼国际物流<br />TMS 管理终端</div>
    </div>

    <div class="login-box">
      <h1>登录</h1>
      <van-field
        v-model="account"
        label="用户名："
        label-width="5em"
        placeholder="请输入用户名/手机号码"
      />
      <van-field
        v-model="password"
        label="密码："
        label-width="5em"
        type="password"
        placeholder="请输入用户名"
      />
    </div>

    <div class="btn-box">
      <van-button style="width: 60%" round type="primary" @click="login()">
        登录
      </van-button>
    </div>
  </div>
</template>

<script>
import { userLogin } from "@/network/api"; //公共接口
import { getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    const store = useStore();
    const router = useRouter();
    const account = ref(""); //账号
    const password = ref(""); //密码

    //登录
    const login = () => {
      if (cerifyAP()) {
        userLogin({
          account: account.value, //用户名
          password: password.value, //密码
        })
          .then((res) => {
            const d = res.data;
            setUsetInfo(d.username, d.nickname, d.sign, d.token, d.avatar); //设置用户信息
            utils.success("登录成功，欢迎你：" + res.data.nickname);
            goToHome(); //回到首页
          })
          .catch((err) => {
            utils.fail(err.err_msg);
          });
      }
    };

    //设置用户信息
    const setUsetInfo = (username, nickname, sign, token, avatar) => {
      store.commit("UserModule/setUserName", username); //用户名
      store.commit("UserModule/setNickName", nickname); //用户昵称
      store.commit("UserModule/setSign", sign); //个签
      store.commit("UserModule/setToken", token); //token
      store.commit("UserModule/setProfilePhoto", avatar); //头像
      store.commit("UserModule/setLoginStatus", true); //写入登录态

      const userinfo = {
        username: username, //用户名
        nickname: nickname, //昵称
        avatar: avatar, //头像
        sign: sign, //个签
        isLogin: true, //是否登录
      };

      localStorage.setItem("userinfo", JSON.stringify(userinfo));
      localStorage.setItem("token", token);
    };

    //回到首页
    const goToHome = () => {
      setTimeout(
        function () {
          router.push({ path: "/", replace: true }); //跳转到首页
        }.bind(this),
        800
      );
    };

    //验证账号密码
    const cerifyAP = () => {
      if (utils.isNull(account.value)) {
        utils.fail("请输入用户名");
        return false;
      }

      if (utils.isNull(password.value)) {
        utils.fail("请输入密码");
        return false;
      }
      return true;
    };

    onMounted(() => {
      document.querySelector("title").innerHTML = "飞鱼国际物流-TMS 登录";
    });

    return {
      login,
      account,
      password,
    };
  },
};
</script>

<style>
.view-box {
  width: 100%;
  height: 100%;
}

.bg-1 {
  width: 100%;
  background-image: url("../assets/img/login-bg-2.png");
}

.bg-1 img {
  width: 100%;
  height: auto;
}

.bg-1 .title {
  position: relative;
  width: 100%;
  height: 35vw;
  margin: auto;
  padding-top: 16vw;
  font-size: 22px;
  font-weight: 600;
  line-height: 35px;
  color: rgb(255, 255, 255);
  text-align: center;
}

.login-box {
  position: relative;
  width: 90%;
  margin: auto;
  margin-top: -2rem;
  padding: 15px 8px;
  padding-bottom: 3rem;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
}

.login-box h1 {
  padding-left: 1rem;
}

.btn-box {
  position: relative;
  width: 90%;
  margin: auto;
  margin-top: -2rem;
  text-align: center;
}

.login-btn {
  width: 60%;
}

.van-field {
  margin: 2rem auto;
}
</style>
